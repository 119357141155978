import {useEffect} from 'react';
import './App.css';
import gotmngrimg from './gotupdates.jpg';
import ReactGA from "react-ga";

function App() {

  useEffect(() => {
    ReactGA.initialize('G-FDNPJTQVN6');
  })

  // Maybe create a small reminder app
  // Remind me to "text here" in/every min/hour/day

  return (
    <div className="App">
      <header className="App-header">
        <img
        style={{
          borderRadius: '5px',
          boxShadow: '0px 0px 20px 3px rgba(0,0,0,0.5)',
          maxWidth: '100%',
          margin: '20px',
          display: 'block'
        }}
        src={gotmngrimg}
        alt="Best manager ever developed"
        />
      </header>
    </div>
  );
}

export default App;
